export const environment = {
  production: true,
  version: require('../../package.json').version,
  platform: 'uat',
  appInsights: {
    instrumentationKey: 'd2fbd847-7871-4355-88be-ca89b6113e40',
    enableApplicationInsights: false
  },
  API_URL: 'https://uatapi.mxnodeapp.co.za/v2/'
};
